body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header2 {
  background: #001529;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header2 .home-page {
  padding: 0 24px;
}
.header2-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header2-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header2-logo a {
  display: block;
}
.header2-menu {
  float: right;
}
.header2-menu > .menu-item {
  line-height: 62px;
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header2-menu > .menu-item.active,
.header2-menu > .menu-item:hover {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
@media screen and (max-width: 767px) {
  .header2-logo {
    z-index: 101;
  }
  .header2.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header2-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-menu .menu-item {
    display: block;
    width: 100%;
    padding: 0 24px;
    border-bottom: none;
    line-height: 40px;
    height: 40px;
    margin: 4px 0 8px;
  }
  .header2-menu .menu-item.active,
  .header2-menu .menu-item:hover {
    border: none;
    background: #1890ff;
    color: #fff;
  }
  .header2-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header2-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-mobile-menu :first-child {
    margin-top: 0;
  }
  .header2 .open {
    height: auto;
  }
  .header2 .open .header2-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header2 .open .header2-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header2 .open .header2-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header2 .open .header2-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 48px;
  line-height: 1.5;
  color: #5f9bf1;
  margin-bottom: 8px;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 24px;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  color: #666;
  margin-bottom: 48px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: absolute;
  top: 15%;
  right: 24px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content2-wrapper {
  height: 360px;
}
.content2-wrapper .content2 {
  height: 100%;
  padding: 0 24px;
}
.content2-wrapper .content2-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content2-wrapper .content2-img span {
  display: block;
  width: 250px;
}
.content2-wrapper .content2-img span img {
  display: block;
}
.content2-wrapper .content2-text {
  padding: 0 32px;
  height: 100%;
}
.content2-wrapper .content2-text .content2-content,
.content2-wrapper .content2-text .content2-title {
  position: relative !important;
}
.content2-wrapper .content2-text .content2-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content2-wrapper .content2-text .content2-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content2-wrapper {
    height: 600px;
  }
  .content2-wrapper .content2-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content2-wrapper .content2-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content2-wrapper .content2-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content2-wrapper .content2-text .content2-content,
  .content2-wrapper .content2-text .content2-title {
    width: 100%;
    top: auto;
  }
  .content2-wrapper .content2-text .content2-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
#Banner0_0 .banner0-text-wrapper > .l31a810ch09-editor_css {
  font-size: 24px;
}
#Banner5_0 .home-page > .l31abip204-editor_css {
  transition: left 1s ease 0.5s;
}
#Content1_0 .ant-row > .ant-col > .l31ajrxnu1t-editor_css {
  font-size: 16px;
  color: #4b4b4b;
}
#Feature2_0 .ant-row > .ant-col > .l31ajhho5jn-editor_css {
  font-size: 16px;
  color: #4b4b4b;
}
#Feature1_0 .ant-row > .ant-col > .l31aj1mrqvl-editor_css {
  font-size: 16px;
  color: #4b4b4b;
}
#Banner5_0 .home-page > .banner5-title-wrapper > .l31acyk9d9s-editor_css {
  font-size: 16px;
  color: #4b4b4b;
}
#Banner5_0 .home-page > .banner5-title-wrapper > .l31achqv95e-editor_css {
  font-size: 40px;
  color: #040c16;
}
#Content1_0 .ant-row > .ant-col > .l31airzz9q9-editor_css {
  font-family: 'pingfang sc', -apple-system, BlinkMacSystemFont, 'segoe ui', 'hiragino sans gb', 'microsoft yahei', 'helvetica neue', Helvetica, Arial, sans-serif, 'apple color emoji', 'segoe ui emoji', 'segoe ui symbol';
  font-size: 40px;
  font-weight: 500;
  line-height: 1.5;
  color: #040c16;
}
#Feature1_0 .ant-row > .ant-col > .l31aiyy6klo-editor_css {
  font-family: 'pingfang sc', -apple-system, BlinkMacSystemFont, 'segoe ui', 'hiragino sans gb', 'microsoft yahei', 'helvetica neue', Helvetica, Arial, sans-serif, 'apple color emoji', 'segoe ui emoji', 'segoe ui symbol';
  font-size: 40px;
  color: #040c16;
}
#Feature2_0 .ant-row > .ant-col > .l31aiw7fe29-editor_css {
  font-family: 'pingfang sc', -apple-system, BlinkMacSystemFont, 'segoe ui', 'hiragino sans gb', 'microsoft yahei', 'helvetica neue', Helvetica, Arial, sans-serif, 'apple color emoji', 'segoe ui emoji', 'segoe ui symbol';
  font-size: 40px;
  font-weight: 500;
  line-height: 1.5;
  color: #040c16;
}
#Banner5_0 .l31abuncxis-editor_css {
  background-color: rgba(255, 255, 255, 0);
}
#Banner5_0.l31aektixsa-editor_css {
  background-color: #ffffff;
  background-image: ;
  background-attachment: ;
  background-blend-mode: ;
  background-position: ;
  background-repeat: ;
  background-size: ;
  background-clip: ;
}
#Banner0_0 .banner0-text-wrapper > .l31a936h5vo-editor_css {
  border-top-color: initial;
}
#Footer0_0.l31jai1rwzs-editor_css {
  background-color: #010219;
}
#Banner0_0 .banner0-text-wrapper > .l31a70444uo-editor_css {
  display: inline;
  justify-content: space-around;
  width: 320px;
  left: 0;
}
#Banner5_0 .home-page > .l31aax06s0p-editor_css {
  transition: opacity 1s ease 0s;
}
#Nav2_0.l31a6nzhqj8-editor_css {
  align-items: flex-start;
  justify-content: space-around;
  color: #020414;
  position: fixed;
  background-color: #010219;
}
#Nav2_0.l31a6nzhqj8-editor_css:hover {
  text-align: justify;
}
#Banner0_0.l31a731c7pt-editor_css {
  background-image: url(https://github.com/shuinye/network/blob/main/banner3.gif?raw=true);
}
#Nav2_0 .home-page > .header2-menu > .l32gx2gunlk-editor_css {
  color: #ffffff;
  border-bottom-color: initial;
}
#Nav2_0 .home-page > .header2-menu > .l32gx2gunlk-editor_css:hover {
  color: #fc2828;
  border-bottom-color: #fc2828;
}
#Nav2_0 .home-page > .header2-menu > .l32gx2gunlk-editor_css:active {
  border-bottom-color: #fc2828;
}
#Nav2_0 .home-page > .header2-menu > .l32gx2gunlk-editor_css:focus {
  border-bottom-color: #fc2828;
}
#Nav2_0 .home-page > .header2-menu > .l32gtzq409t-editor_css {
  color: #ffffff;
  border-bottom-color: initial;
}
#Nav2_0 .home-page > .header2-menu > .l32gtzq409t-editor_css:hover {
  color: #fc2828;
  border-bottom-color: #fc2828;
}
#Nav2_0 .home-page > .header2-menu > .l32gtzq409t-editor_css:active {
  color: #fc2828;
  border-bottom-color: #fc2828;
}
#Nav2_0 .home-page > .header2-menu > .l32gtzq409t-editor_css:focus {
  color: #fc2828;
  border-bottom-color: #fc2828;
}

